/* eslint-disable jsx-a11y/media-has-caption */
import 'twin.macro';
import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Hero from '../components/home/Hero';
// import About from '../components/home/About';
// import Events from '../components/home/Events';
import config from '../utils/config';
// import Video from '../components/elements/Video';
import HomeDetails from '../components/home/Details';
// import MoonCalender from '../components/home/MoonCalender';

export const query = graphql`
  query HomePageQuery {
    sanitySiteSettings {
      title
      description
      homeBanner {
        _key
        asset {
          gatsbyImageData(width: 1600)
        }
      }
      homeDetails
      homeVideoUrl
    }
    allSanityEvent(limit: 9, sort: { fields: fromDate, order: DESC }) {
      edges {
        node {
          _id
          title
          place
          fromDate
          toDate
          email
        }
      }
    }
  }
`;
const IndexPage = ({ data }) => {
  const home = data.sanitySiteSettings;
  // const events = data.allSanityEvent.edges;

  return (
    <Layout>
      <Seo
        title={home.title}
        description={home.description}
        url={config.siteUrl}
        image={config.logo}
      />
      <Hero data={home.homeBanner} />
      {/* <MoonCalender /> */}
      <HomeDetails data={home} />
      {/* {home.homeVideoUrl && (
        <Video title={home.title} src={home.homeVideoUrl} />
      )}
      {events.length > 0 && <Events events={events} />} */}
    </Layout>
  );
};
export default IndexPage;
