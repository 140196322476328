/* eslint-disable no-underscore-dangle */
import 'twin.macro';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'gatsby';
import SanityImage from '../SanityImage';
import { Button } from '../atoms';

const Hero = ({ data }) => {
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2500,
    cssEase: 'linear',
  };
  return (
    <div tw="w-full mx-auto max-w-screen-2xl">
      <Slider {...settings}>
        {data &&
          data.map((item) => (
            <div key={item._key} tw="max-w-full">
              <SanityImage fluid={item.asset.gatsbyImageData} />
              <div tw="grid grid-cols-2 gap-3 justify-center mt-14 max-w-md mx-auto px-3">
                <Link to="/schedule">
                  <Button isRounded isFullWidth>
                    View Schedule
                  </Button>
                </Link>
                <Link to="/enrol">
                  <Button isRounded isFullWidth>
                    Enrol Now
                  </Button>
                </Link>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};
export default Hero;
