import { styled } from 'twin.macro';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const Container = styled.div`
  h1 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
  h3 {
    font-size: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
  li {
    list-style: disc;
    margin-left: 1rem;
  }
  p {
    margin-bottom: 1rem;
    font-size: 16px;
  }
  .page-content {
    img {
      display: flex;
      margin: 3rem auto 4rem auto;
      box-shadow: ${(props) => props.theme.boxShadow};
    }
  }
`;

const HomeDetails = ({ data }) => (
  <Container>
    <div tw="container mx-auto px-4 max-w-screen-lg">
      <div tw="mb-24 mt-20">
        <ReactMarkdown>{data.homeDetails}</ReactMarkdown>
      </div>
    </div>
  </Container>
);

export default HomeDetails;
